import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--dark"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"margin": "0 0 24px 0",
			"children": <Strong font="--headline2">
				Часті запитання
			</Strong>
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "1. Чи є у вас навчання на автомобілях з автоматичною коробкою передач?"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "12px 0",
			"border-color": "--color-darkL1",
			"children": "Так, ми маємо сучасні автомобілі з АКПП."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "33.333%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "2. Чи можу я обрати інструктора?"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "12px 0",
			"border-color": "--color-darkL1",
			"children": "Так, ми враховуємо ваші побажання при виборі інструктора."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "33.333%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "3. Чи допомагаєте ви з реєстрацією на іспит?"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "12px 0",
			"border-color": "--color-darkL1",
			"children": "Так, ми забезпечуємо повну підтримку під час реєстрації та складання іспитів."
		}
	}
};

const Faq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text1")} />
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Box {...override("box6")}>
					<Text {...override("text5")} />
					<Text {...override("text6")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq, { ...Section,
	defaultProps,
	overrides
});
export default Faq;