import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Box, Link, Strong, Button, Section } from "@quarkly/widgets";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
const defaultProps = {
	"color": "--light",
	"padding": "100px 0",
	"sm-padding": "40px 0",
	"position": "relative",
	"background": "linear-gradient(0deg,rgba(3, 4, 48, 0.87) 0%,rgba(3, 4, 48, 0.9) 100%),--color-darkL2 url(https://uploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/d31c400996334a829a5be50d8600e8db.webp?v=2024-11-20T07:47:23.232Z) 50%/cover",
	"quarkly-title": "Form-1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "50%",
			"md-width": "100%",
			"order": "1",
			"text-align": "left"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px aut",
			"text-align": "left",
			"opacity": ".8"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"sm-padding": "64px 0 0 0",
			"margin": "32px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"padding": "0 0 0 64px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdLocationOn,
			"position": "absolute"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "4998 Hanover Street, New York, 10011"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"padding": "0 0 0 64px",
			"sm-padding": "64px 0 0 0",
			"margin": "64px 0 0 0",
			"max-width": "360px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail,
			"position": "absolute",
			"size": "48px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": <Link href="mailto:hello@company.com" text-decoration="none" hover-text-decoration="underline" color="--light">
				hello@company.com
			</Link>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"left": "0",
			"category": "md",
			"icon": MdPhone,
			"position": "absolute",
			"size": "48px",
			"top": "0"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": <>
				+1 (234) 567-89-00
				<br />
				+1 (234) 567-89-00
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"padding": "8px 8px 8px 8px",
			"md-width": "100%"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 1rem 0px",
			"font": "--headline1",
			"children": <>
				Ласкаво просимо до{" "}
				<Strong>
					DrivePro
				</Strong>
			</>
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--lead",
			"children": "Сучасна автошкола, яка допоможе вам впевнено керувати автомобілем"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-secondary",
			"margin": "1rem 0px 0px 0px",
			"type": "link",
			"text-decoration-line": "initial",
			"href": "/poslugi-ta-cini"
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Icon {...override("icon")} />
						<Text {...override("text")} />
					</Box>
					<Box {...override("box4")}>
						<Icon {...override("icon1")} />
						<Text {...override("text1")} />
					</Box>
					<Box {...override("box5")}>
						<Icon {...override("icon2")} />
						<Text {...override("text2")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Box {...override("box7")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
					<Button {...override("button")}>
						Послуги
					</Button>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;