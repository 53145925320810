import React from "react";
import { useOverrides, Override, StackItem, Stack } from "@quarkly/components";
import { Text, Strong, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "linear-gradient(0deg,rgba(3, 4, 48, 0.79) 0%,--color-primary 100%),--color-light url(https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z) center/cover",
	"padding": "84px 0 84px 0",
	"sm-padding": "40px 0",
	"color": "--light",
	"font": "--base"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"width": "75%",
			"lg-width": "100%"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--lightD2",
			"letter-spacing": "1px",
			"text-transform": "uppercase",
			"margin": "0",
			"children": <>
				У{" "}
				<Strong>
					Study Prosper Hub
				</Strong>
				{" "}ми допомагаємо зробити цей важливий крок, забезпечуючи якісне навчання водінню для початківців та вдосконалення навичок для досвідчених водіїв.
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline2",
			"md-font": "--headline2",
			"margin": "10px 0",
			"width": "100%",
			"children": <>
				Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі.{" "}
			</>
		}
	}
};

const Hero12 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Stack {...override("stack")}>
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</StackItem>
		</Stack>
		{children}
	</Section>;
};

Object.assign(Hero12, { ...Section,
	defaultProps,
	overrides
});
export default Hero12;